.cover-container {
  height: 100vh;
  width: 100%;
  object-fit: contain;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
}

.cover-container > h1 {
  color: white;
  font-size: 100px;
}

.cover-container > p {
  margin-top: 8px;
  color: white;
  font-size: 32px;
}


.buttonBlue {
  padding: 8px 40px;
  border-radius: 40px;
  background-color: #4e73df;
  border-color : #4e73df ;
  line-height: 1.5;
  box-shadow: none ;
  font-size: 16px;
  color: white;
  font-weight: 500;
  outline: rgba(105,136,228,.5);    
  cursor: pointer;
  width : 350px;
  height: 50px;
}
  
.buttonBlue:hover {
  background-color: #2653d4; 
  border-color: #0062cc;
  box-shadow: none;
  color: white;

}

.buttonBlue:active {
  background-color: #0062cc; 
  border-color: rgba(105,136,228,.5);
  box-shadow: none;
  color: white;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
  
}

@media screen and (max-width: 960px) {
  .cover-container > h1 {
    font-size: 70px;
    text-align: center;
  }

  .cover-container > p {
    text-align: center;
    font-size: 22px;
    width: 85%;
  }
}